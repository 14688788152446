import React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "gatsby";

const video = ({ buttonLabel, link }) => {
    return (

            <Link to={link}><Button>{buttonLabel}</Button></Link>
        
    );
};

video.propTypes = {
    buttonLabel: PropTypes.node.isRequired,
};



const Button = styled.a`
    background: #355A2A;
    padding: .5em 1em;
    color: white;
    display: inline-block;
    &:hover {
        background: dark-green;
    }
`;

export default video;