import React from "react"
import Layout from '../components/layout'
import H2 from '../components/typography/h2'
// import SmallHero from '../components/small-hero'
import styled from "styled-components"
import { Helmet } from "react-helmet"
// import Hero from '../components/hero-small'
import Button from '../components/button'
import Img from "gatsby-image/withIEPolyfill"
import { graphql } from 'gatsby'

const Services = ({ data }) => {

    return (
        <React.Fragment>
            <Layout>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Services | Collins Sporthorses</title>
                <meta name="description" content="Check out the services that we offer at Collins Sporthorses. From training to amazing facilities. We can help your horse be the best it can be." />
            </Helmet>

            <SlideImage>
            <Img
                fluid={data.hero.childImageSharp.fluid}
                objectFit='cover'
                objectPosition='50% 10%'
                alt='horses'
                style={{ width: "100%", height: "100%" }}
            />
            </SlideImage>
            <Wrapper>
                <Content>
                    <h2>Services</h2>
                </Content>
            </Wrapper>

                <Container>

                    <Service>
                        <H2>Training Packages</H2>
                        <p>Includes 4-5 days a week training rides. Horses are fed a quality Bermuda hay and Purina horse feed 2 times a day (3 rd feeding is available at an extra charge). Also included in training is daily stall cleaning, blanketing and administering any medications. One lesson a week is included with the full training package. Additional lessons will be billed at the normal lesson rate.</p>
                    </Service>

                    <Service>
                        <H2>Boarding</H2>
                        <p>Boarding is available at Collins Sporthorses, but is limited. Includes feeding,
                        stall cleaning and turnout. One lesson or training ride per week is required.</p>
                    </Service>

                    <Service>
                        <H2>Consignment Program</H2>
                        <p>Melissa has an extensive and busy consignment program, selling on average 1-4 horses a month. She
                        prides herself on matching horse with a new rider and finding the perfect home for each horse. She
                        makes the process quick and easy and less stress on you, the seller. Please contact her for current rates.</p>
                    </Service>

                    <Service>
                        <H2>Lessons</H2>
                        <p>Lessons are available 5 days a week (Tuesday-Saturday). Lessons are approximately 50-60 minutes. We have a limited number of lesson horses available or you are welcome to haul your horse in.
                        Beginner to advanced lessons welcome.</p>
                    </Service>

                    <Service>
                        <H2>Kid's Camp</H2>
                        <p>Collins Sporthorses offers a yearly horse camp in June for kids of all levels of riding, ages 5 - 14. Activities include: daily lesson, horsemanship lesson, vet and farrier seminars, daily horse craft, horse games and more! This has been a huge hit for our kinds in our area. Please sign up early to reserve your spot!</p>
                        <Button link="/services/camp/" buttonLabel="Sign Up" />
                    </Service>

                    <Service>
                        <H2>Horse Showing</H2>
                        <p>Melissa frequently travels to horse shows of all levels from local KHJA circuit to AA nationally rated shows. Frequent shows are Brownland, Memphis, Kentucky horse shows, World Equestrian Center and Tryon. Please contact Melissa for upcoming shows and the show schedule.</p>
                    </Service>

                    <Service>
                        <H2>Equine Amenities</H2>
                        <ul>
                            <li>12x12 Stalls with fan</li>
                            <li>120x240 lighted riding arena with all-weather footing</li>
                            <li>Daily Turnout (weather permitting)</li>
                            <li>Hot and cold wash rack</li>
                            <li>Tack room</li>
                            <li>Round pen</li>
                            <li>Access to outside hacking areas</li>
                            <li>Arena with full set of jumps</li>
                            <li>Management live on premises</li>
                        </ul>
                    </Service>

                </Container>

            </Layout>

        </React.Fragment>
    )
}

export default Services

const Container = styled.div`
    width: 90%;
    max-width: 1300px;
    margin: 3em auto;
}
`

const SlideImage = styled.div`
position: absolute;
width: 100%;
z-index: 0;
height: 300px;
@media (min-width: 800px) {    
    height: 300px;
}
`;

const Content = styled.div`
max-width: 300px;
position: absolute;
text-align: left;
top: 15%;
text-shadow: 2px 0px 10px rgba(0,0,0,0.74);
h2 {
    font-family: 'Playfair Display', serif;
    font-size: 3em;
}
p {
  font-size: 1.5em;
  font-weight: 700; 
}
@media (min-width: 800px) {
    max-width: 475px;
    h2 {
        font-size: 4em;
        margin-bottom: 25px;
    }
}
`;

const Wrapper = styled.div`
color: #fff;
height: 300px;
margin: auto;
max-width: 85%;
position: relative;
width: 85%;
z-index: 1;
a {
    text-decoration: none;
}
@media (min-width: 800px) {
    height: 300px;
    max-width: 1600px;
    width: 85%;
}
`;

const Service = styled.section`
    border: solid 1px #CBCBCB;
    padding: 2em;
    margin: 1em 0;
    p, ul {
        font-family: 'Lora', serif;
        font-size: 1.2em;
        line-height: 1.75em;
      }
`

export const query = graphql`
query {
  hero: file(relativePath: { eq: "images/services.JPG" }) {
    childImageSharp {
      fluid(maxWidth: 1500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

